/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled, { css } from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

interface TextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const Section = styled.section<{ bgColor: string }>`
  ${(props) =>
    props.bgColor === 'dark' &&
    css`
      background-color: ${({ theme }) => theme.color.dark};
    `}
`

const Content = styled(ParseContent)`
  & a {
    text-decoration: none;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    &:hover {
      text-decoration: underline;
    }
  }

  & h1,
  & h2,
  & h3 {
    margin-bottom: 0;
  }

  @media (max-width: 350px) {
    & h1,
    & h2,
    & h3 {
      & span {
        font-size: 50px;
      }
    }
  }
`

const Image = styled(Plaatjie)<{ bgColor: string }>`
  ${(props) =>
    props.bgColor === 'primary' &&
    css`
      height: 446px;
    `}

  @media (max-width: 575px) {
    ${(props) =>
      props.bgColor === 'primary' &&
      css`
        height: 276px;
      `}
  }

  max-height: 400px;
`

const Beam = styled.div`
  top: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.color.primary};

  @media (min-width: 992px) {
    height: 289px;
  }
`

const Container = styled.div<{ top: boolean }>`
  ${(props) =>
    props.top &&
    css`
      top: 70px;
    `}
`

const Text: React.FC<TextProps> = ({ fields }) => (
  <Section
    bgColor={fields.options?.background || 'primary'}
    className="position-relative"
  >
    {fields.options?.background === 'primary' && (
      <Beam className="position-absolute" />
    )}
    <Container
      className="position-relative container py-lg-0 py-3"
      top={fields.options?.background === 'primary'}
    >
      <div className={`row justify-content-${fields.options?.alignment}`}>
        {fields.texts?.map((block, index) => (
          <div
            key={index}
            className={`col-lg-${block?.width} d-flex align-items-center ${
              index === 0 ? `mb-lg-0 mb-3` : ``
            } `}
          >
            {block?.textorimage === 'text' && <Content content={block.text} />}
            {block?.textorimage === 'image' && (
              <div
                className={`py-lg-4 py-2 ${
                  fields.options?.background === 'primary' ? 'mx-lg-3' : ''
                }`}
              >
                <Image
                  image={block.image}
                  alt=""
                  bgColor={fields.options?.background || 'primary'}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </Container>
  </Section>
)

export default Text
